<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The equilibrium constant,
        <stemble-latex content="$\text{K}_\text{c},$" />
        for the reaction show below is
        <number-value :value="Kc" />
        at
        <number-value :value="Temp" unit="^\circ\text{C.}" />
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 X(g) + 2 Y(g) <=> Z(g)" />
      </p>

      <p class="mb-2">
        Determine the value of
        <stemble-latex content="$\text{K}_\text{p}$" />
        for the reaction at this temperature.
      </p>

      <calculation-input
        v-model="inputs.Kp"
        prepend-text="$\text{K}_\text{p}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question108',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Kp: null,
      },
    };
  },
  computed: {
    Kc() {
      return this.taskState.getValueBySymbol('Kc').content;
    },
    Temp() {
      return this.taskState.getValueBySymbol('Temp').content;
    },
  },
};
</script>
